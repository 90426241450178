<template>
  <div v-loading="loading">
    <el-card shadow="never" :body-style="{ padding: '20px' }">
      <div slot="header">
        <span class="iconfont icon-VideoNotice">&nbsp;视频通知</span>
      </div>
      <div class="card_header_flex">
        <div>
          <el-button type="primary" size="small" @click="dialogAddNotice=true">新增通知</el-button>
        </div>
      </div>
      <el-table :data="list" size="mini">
        <el-table-column prop="room" label="房间号">
          <template slot-scope="scope">{{scope.row.tag=='on'?'所有房间':scope.row.room}}</template>
        </el-table-column>
        <el-table-column prop="message" label="通知内容">
          <template slot-scope="scope">
            <video :src="fileUrl+scope.row.message" controls="controls" style="width:100%;"></video>
          </template>
        </el-table-column>
        <el-table-column prop="display" label="展示方式">
          <template slot-scope="scope">{{scope.row.display=='desk'?'弹窗显示':'全屏显示'}}</template>
        </el-table-column>
        <el-table-column label="开始时间/截止时间">
          <template slot-scope="scope">
            <span
              v-if="scope.row.effect=='only'"
            >{{scope.row.createtimeDate}}~{{scope.row.endtimeDate}}</span>
            <span v-else>{{subStr(scope.row.createtimeStr)}}~{{subStr(scope.row.endtimeStr)}}</span>
          </template>
        </el-table-column>
        <el-table-column label="有效期" width="200">
          <template slot-scope="scope">
            <span>{{scope.row.effect=='only'?scope.row.isExpire:'每天'}}</span>
          </template>
        </el-table-column>
        <el-table-column label="是否启用" width="100">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.state"
              active-value="on"
              inactive-value="off"
              @change="onChangeSubmitState"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="openEditNotice(scope.row)">编辑</el-button>
            <el-button type="text" size="small" @click="delNotice(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 添加通知 -->
    <el-dialog
      :visible.sync="dialogAddNotice"
      :close-on-click-modal="false"
      width="40%"
      @closed="closeDialogAddNotice"
    >
      <span slot="title">新增通知</span>
      <el-form
        :model="form"
        ref="form"
        label-width="80px"
        size="small"
        style="padding:20px"
        hide-required-asterisk
      >
        <el-form-item label="通知类型:">视频通知</el-form-item>
        <el-form-item label="通知内容">
          <el-dropdown placement="bottom">
            <div>
              <div class="avatar-uploader-logo" v-if="!form.message">
                <i class="el-icon-plus avatar-uploader-logo-icon"></i>
              </div>
              <video v-else :src="fileUrl+form.message" controls="controls" style="width:200px;"></video>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                @click.native="dialogAddFileUpload=true"
                style="padding:0"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;资源库选择&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-dropdown-item>
              <el-dropdown-item style="padding:0">
                <el-upload
                  style="text-align:center"
                  :action="actionUrl"
                  accept=".mp4, .flv, .ts, .MP4, .FLV, .TS"
                  :headers="header"
                  :data="{type:1,category:'背景视频'}"
                  :show-file-list="false"
                  :on-success="handleAddSuccess"
                  :before-upload="beforeAddUpload"
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本地上传&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-upload>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-form-item>
        <el-form-item label="房间号">
          <el-checkbox
            v-model="form.tag"
            @change="onChangecheckAll"
            true-label="on"
            false-label="off"
          >全部</el-checkbox>
          <div class="down-tree">
            <el-tag
              v-for="item in roomList"
              :key="item.clientId"
              @click="item.isSelected=!item.isSelected"
              :effect="item.isSelected?'dark':'light'"
              style="margin:5px 10px;padding:0 30px;cursor: pointer;  -webkit-user-select: none;-moz-user-select: none;-o-user-select: none;user-select: none;"
            >{{item.room}}</el-tag>
          </div>
        </el-form-item>
        <el-form-item label="生效方式">
          <el-radio v-model="form.effect" label="only">一次性</el-radio>
          <el-radio v-model="form.effect" label="every">每天</el-radio>
        </el-form-item>
        <el-row :gutter="20" v-if="form.effect=='only'">
          <el-col :span="12">
            <el-form-item
              label="生效时间"
              prop="createtimeDate"
              :rules="{ required: true, message: '请选择开始日期', trigger: 'change' }"
            >
              <el-date-picker
                v-model="form.createtimeDate"
                type="datetime"
                placeholder="请选择开始日期"
                value-format="yyyy-MM-dd HH:mm:ss"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="截止时间"
              prop="endtimeDate"
              :rules="{ required: true, message: '请选择截止日期', trigger: 'change' }"
            >
              <el-date-picker
                v-model="form.endtimeDate"
                type="datetime"
                placeholder="请选择截止日期"
                value-format="yyyy-MM-dd HH:mm:ss"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" v-else>
          <el-col :span="12">
            <el-form-item
              label="生效时间"
              prop="createtimeStr"
              :rules="{ required: true, message: '请选择开始时间', trigger: 'change' }"
            >
              <el-time-select
                v-model="form.createtimeStr"
                :picker-options="{start: '00:00',step: '00:10',end: '23:50'}"
                placeholder="请选择开始时间"
              ></el-time-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="截止时间"
              prop="endtimeStr"
              :rules="{ required: true, message: '请选择截止时间', trigger: 'change' }"
            >
              <el-time-select
                v-model="form.endtimeStr"
                :picker-options="{start: '00:00',step: '00:10',end: '23:50'}"
                placeholder="请选择截止时间"
              ></el-time-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="展示方式">
          <el-radio v-model="form.display" label="desk">弹窗显示</el-radio>
          <el-radio v-model="form.display" label="fullscreen">全屏显示</el-radio>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button plain type="primary" size="small" @click="dialogAddNotice=false">取 消</el-button>
        <el-button type="primary" size="small" @click="onSubmitAddNotice">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改通知 -->
    <el-dialog :visible.sync="dialogEditNotice" :close-on-click-modal="false" width="40%">
      <span slot="title">编辑通知</span>
      <el-form
        :model="editForm"
        ref="editForm"
        label-width="80px"
        size="small"
        style="padding:20px"
        hide-required-asterisk
      >
        <el-form-item label="通知类型:">视频通知</el-form-item>
        <el-form-item label="通知内容">
          <el-dropdown placement="bottom">
            <div>
              <div class="avatar-uploader-logo" v-if="!editForm.message">
                <i class="el-icon-plus avatar-uploader-logo-icon"></i>
              </div>
              <video
                v-else
                :src="fileUrl+editForm.message"
                controls="controls"
                style="width:200px; margin-top:20px"
              ></video>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                @click.native="dialogEditFileUpload=true"
                style="padding:0"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;资源库选择&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-dropdown-item>
              <el-dropdown-item style="padding:0">
                <el-upload
                  style="text-align:center"
                  :action="actionUrl"
                  accept=".mp4, .flv, .ts, .MP4, .FLV, .TS"
                  :headers="header"
                  :data="{type:1,category:'背景视频'}"
                  :show-file-list="false"
                  :on-success="handleEditSuccess"
                  :before-upload="beforeEditUpload"
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本地上传&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-upload>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-form-item>
        <el-form-item label="房间号">
          <el-checkbox
            v-model="editForm.tag"
            @change="onChangecheckAll"
            true-label="on"
            false-label="off"
          >全部</el-checkbox>
          <div class="down-tree">
            <el-tag
              v-for="item in roomList"
              :key="item.clientId"
              @click="item.isSelected=!item.isSelected"
              :effect="item.isSelected?'dark':'light'"
              style="margin:5px 10px;padding:0 30px;cursor: pointer;  -webkit-user-select: none;-moz-user-select: none;-o-user-select: none;user-select: none;"
            >{{item.room}}</el-tag>
          </div>
        </el-form-item>
        <el-form-item label="生效方式">
          <el-radio v-model="editForm.effect" label="only">一次性</el-radio>
          <el-radio v-model="editForm.effect" label="every">每天</el-radio>
        </el-form-item>
        <el-row :gutter="20" v-if="editForm.effect=='only'">
          <el-col :span="12">
            <el-form-item
              label="生效时间"
              prop="createtimeDate"
              :rules="{ required: true, message: '请选择开始日期', trigger: 'change' }"
            >
              <el-date-picker
                v-model="editForm.createtimeDate"
                type="datetime"
                placeholder="请选择开始日期"
                value-format="yyyy-MM-dd HH:mm:ss"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="截止时间"
              prop="endtimeDate"
              :rules="{ required: true, message: '请选择截止日期', trigger: 'change' }"
            >
              <el-date-picker
                v-model="editForm.endtimeDate"
                type="datetime"
                placeholder="请选择截止日期"
                value-format="yyyy-MM-dd HH:mm:ss"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" v-else>
          <el-col :span="12">
            <el-form-item
              label="生效时间"
              prop="createtimeStr"
              :rules="{ required: true, message: '请选择开始时间', trigger: 'change' }"
            >
              <el-time-select
                v-model="editForm.createtimeStr"
                :picker-options="{start: '00:00',step: '00:10',end: '23:50'}"
                placeholder="请选择开始时间"
              ></el-time-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="截止时间"
              prop="endtimeStr"
              :rules="{ required: true, message: '请选择截止时间', trigger: 'change' }"
            >
              <el-time-select
                v-model="editForm.endtimeStr"
                :picker-options="{start: '00:00',step: '00:10',end: '23:50'}"
                placeholder="请选择截止时间"
              ></el-time-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="展示方式">
          <el-radio v-model="editForm.display" label="desk">弹窗显示</el-radio>
          <el-radio v-model="editForm.display" label="fullscreen">全屏显示</el-radio>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button plain type="primary" size="small" @click="dialogEditNotice=false">取 消</el-button>
        <el-button type="primary" size="small" @click="onSubmitEditNotice">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 添加窗口视频 -->
    <el-dialog :visible.sync="dialogAddFileUpload" :close-on-click-modal="false" width="55%">
      <span slot="title">资源库</span>
      <FileUploadVideo
        :type="1"
        :category="'背景视频'"
        v-on:callBackPath="onSubmitAddNoticeVideo"
        v-if="dialogAddFileUpload"
      ></FileUploadVideo>
    </el-dialog>

    <!-- 修改窗口视频 -->
    <el-dialog :visible.sync="dialogEditFileUpload" :close-on-click-modal="false" width="55%">
      <span slot="title">资源库</span>
      <FileUploadVideo
        :type="1"
        :category="'背景视频'"
        v-on:callBackPath="onSubmitEditNoticeVideo"
        v-if="dialogEditFileUpload"
      ></FileUploadVideo>
    </el-dialog>
  </div>
</template>
<script>
import {
  getTerminalApi,
  getPushApi,
  addPushApi,
  editPushApi,
  delPushApi
} from "../../../api/data";
import Aes from "../../../utils/aes";
import FileUploadVideo from "../../../components/FileUploadVideo";
import DateFormat from "../../../utils/date";
export default {
  components: {
    FileUploadVideo
  },
  data() {
    return {
      loading: true,
      list: [],
      dialogAddNotice: false, //添加窗口
      form: {
        message: "",
        clientId: "",
        type: "video",
        groupId: "",
        createtimeStr: "",
        endtimeStr: "",
        createtimeDate: "",
        endtimeDate: "",
        display: "desk", //显示范围 desk fullScreen所有APP
        effect: "only", //生效方式
        tag: "off" //勾选全部房间按钮
      },
      dialogEditNotice: false, //修改窗口
      editForm: {},
      roomList: [], //房间列表
      dialogAddFileUpload: false, //添加推送图片窗口
      dialogEditFileUpload: false //修改推送图片窗口
    };
  },
  computed: {
    placesInfo() {
      return JSON.parse(Aes.decode(this.$store.state.user.placesInfo));
    },
    header() {
      //上传头部POST请求头部带token信息验证登录状态
      return {
        accessToken: this.$store.state.user.authorization
      };
    },
    //图片显示地址
    fileUrl() {
      return this.$store.state.fileUrl;
    },
    //上传路径
    actionUrl() {
      return this.$store.state.fileUrl + "/file/upload";
    }
  },
  created() {
    this.initLoad();
    this.initLoadTerminal();
  },
  methods: {
    //初始化推送
    initLoad() {
      getPushApi({ groupId: this.placesInfo.groupId, type: "video" }).then(
        res => {
          this.loading = false;
          if (res) {
            for (let i = 0; i < res.data.length; i++) {
              if (res.data[i].effect == "only") {
                res.data[i].isExpire = DateFormat.judgeTime(
                  res.data[i].endtimeDate
                )
                  ? "已过期"
                  : DateFormat.timeDifference(res.data[i].endtimeDate);
              }
            }
            this.list = res.data;
          }
        }
      );
    },
    //初始化终端房间
    initLoadTerminal() {
      getTerminalApi({
        groupId: this.placesInfo.groupId,
        clientType: "all"
      }).then(res => {
        this.roomList = res.data.map(item => {
          return { ...item, isSelected: false };
        });
      });
    },
    //全选房间事件
    onChangecheckAll(val) {
      for (let i = 0; i < this.roomList.length; i++) {
        this.roomList[i].isSelected = val == "on" ? true : false;
      }
    },
    //添加推送
    onSubmitAddNotice() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.roomList.length == 0) {
            this.$message.warning("请选择房间");
            return false;
          }
          if (this.form.message == "") {
            this.$message.warning("请上传视频");
            return false;
          }
          this.form.clientId = "";
          //添加当前场所ID进表单
          this.form.groupId = this.placesInfo.groupId;
          //添加已勾选的房间
          for (let i = 0; i < this.roomList.length; i++) {
            if (this.roomList[i].isSelected) {
              if (this.form.clientId == "") {
                this.form.clientId = this.roomList[i].clientId;
              } else {
                this.form.clientId =
                  this.form.clientId + "," + this.roomList[i].clientId;
              }
            }
          }
          addPushApi(this.form).then(res => {
            if (res) {
              this.$message.success(res.msg);
              this.initLoad();
              this.dialogAddNotice = false;
              //添加完成后初始化添加数据
              this.form = {
                message: "",
                clientId: "",
                type: "video",
                groupId: "",
                createtimeStr: "",
                endtimeStr: "",
                createtimeDate: "",
                endtimeDate: "",
                display: "desk", //显示范围 desk fullScreen所有APP
                effect: "only", //生效方式
                tag: "off" //勾选全部房间按钮
              };
              this.initLoadTerminal();
            }
          });
        } else {
          return false;
        }
      });
    },
    //添加窗口关闭事件
    closeDialogAddNotice() {
      this.$refs["form"].resetFields();
    },
    //打开修改窗口
    openEditNotice(row) {
      this.editForm = { ...row };
      this.dialogEditNotice = true;
      if (this.editForm.effect == "every") {
        this.editForm.createtimeStr = this.subStr(this.editForm.createtimeStr);
        this.editForm.endtimeStr = this.subStr(this.editForm.endtimeStr);
      }

      var array = this.editForm.clientId.split(",");
      for (let i = 0; i < array.length; i++) {
        for (let j = 0; j < this.roomList.length; j++) {
          if (array[i] == this.roomList[j].clientId) {
            this.roomList[j].isSelected = true;
          }
        }
      }
      this.editForm.clientId = "";
    },
    //修改推送
    onSubmitEditNotice() {
      this.$refs["editForm"].validate(valid => {
        if (valid) {
          if (this.roomList.length == 0) {
            this.$message.warning("请选择房间");
            return false;
          }
          //添加已勾选的房间
          for (let i = 0; i < this.roomList.length; i++) {
            if (this.roomList[i].isSelected) {
              if (this.editForm.clientId == "") {
                this.editForm.clientId = this.roomList[i].clientId;
              } else {
                this.editForm.clientId =
                  this.editForm.clientId + "," + this.roomList[i].clientId;
              }
            }
          }
          editPushApi(this.editForm).then(res => {
            if (res) {
              this.$message.success(res.msg);
              this.initLoad();
              this.dialogEditNotice = false;
              this.initLoadTerminal();
            }
          });
        } else {
          return false;
        }
      });
    },
    //修改推送启用状态
    onChangeSubmitState(row) {
      editPushApi(row).then(res => {
        if (res) {
          this.$message.success(res.msg);
          this.initLoad();
        }
      });
    },
    //删除推送通知
    delNotice(row) {
      this.$confirm("此操作将永久删除该通知, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        delPushApi({ id: row.id }).then(res => {
          if (res) {
            this.$message.success(res.msg);
            this.initLoad();
          }
        });
      });
    },
    //格式化时间去除秒
    subStr(str) {
      if (str) return str.substring(0, str.lastIndexOf(":"));
    },
    //添加推送视频
    onSubmitAddNoticeVideo(path) {
      this.dialogAddFileUpload = false;
      this.form.message = path;
    },
    //上传完成事件
    handleAddSuccess(res) {
      var result = JSON.parse(Aes.decode(res));
      if (result.type == "success") {
        this.form.message = result.msg;
      } else {
        this.$message.error(result.msg);
      }
       this.initLoad();
    },
    //上传前事件
    beforeAddUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 50;
      if (!isLt5M) {
        this.$message.warning("上传图片大小不能超过 50MB!");
      }
      return isLt5M;
    },
    //修改推送中的视频
    onSubmitEditNoticeVideo(path) {
      this.dialogEditFileUpload = false;
      this.editForm.message = path;
    },
    //上传完成事件
    handleEditSuccess(res) {
      var result = JSON.parse(Aes.decode(res));
      if (result.type == "success") {
        this.editForm.message = result.msg;
      } else {
        this.$message.error(result.msg);
      }
       this.initLoad();
    },
    //上传前事件
    beforeEditUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 50;
      if (!isLt5M) {
        this.$message.warning("上传图片大小不能超过 50MB!");
      }
      return isLt5M;
    }
  }
};
</script>
<style lang='scss'>
.down-tree {
  height: 200px;
  display: block;
  overflow-y: auto;
  border: 1px solid #d7dae2;
  border-radius: 4px;
}
.avatar-uploader-logo {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  width: 100px;
}
.avatar-uploader-logo:hover {
  border-color: #5e72f9;
}
.avatar-uploader-logo-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
</style>